<template>
  <div class="dish-container" :class="customClass">
    <div class="dish-card" v-lazyload>
      <img class="dish-img" @click="submitSlider" :data-src="dish?.image?.url" />
      <div class="dish-description m-text-food-title">
        <div class="main-description">
          <div class="icons-box">
            <div class="is-allergie" v-for="icon in dish.tags" :key="icon.id">
              <InfoMessage bottom="38px" left="-21px" arrowDir="bl">
                {{ icon.tooltip }}
              </InfoMessage>
              <img :src="icon.icon.url" alt="icon" />
            </div>
          </div>
           <div class="kkal-box micro">
              <div class="kkal-inner">
                <div @click="switchVariant(index)" v-for="(variant, index) in dish?.info" :key="variant?.id" :class="{ active: index == activeIndexKKal }" class="kkal-cell">{{ finalWeight(variant?.gram) }} г</div>
              </div>
              <button v-if="modifiersLength" @click="modifierToggle()" class="ingredients-toggler" :class="{ active: modFlag, colored: modifiersFlag }">
                {{ DISHES_TRANSLATE?.dish?.modifiers?.title }}
                <svg class="angle" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L4.5 5L8 1" stroke="#000E29" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </button>
            </div>
          <div class="title-top">
            <span class="title">{{ dish.name }}</span>
            <span class="price original-price">{{ finalPrice }} ₴</span>
          </div>
          <div class="product-options">
            <div class="kkal-box">
              <div class="kkal-inner">
                <div @click="switchVariant(index)" v-for="(variant, index) in dish?.info" :key="variant?.id" :class="{ active: index == activeIndexKKal }" class="kkal-cell">{{ finalWeight(variant?.gram) }} г</div>
              </div>
              <button v-if="modifiersLength" @click="modifierToggle" class="ingredients-toggler" :class="{ active: modFlag, colored: modifiersFlag }">
                {{ DISHES_TRANSLATE?.dish?.modifiers?.title }}
                <svg class="angle" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L4.5 5L8 1" stroke="#000E29" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </button>
            </div>
            <div class="price-bottom">
              <span class="price micro-price">{{ finalPrice }} ₴</span>
            </div>
            <div v-if="modifiersLength" class="modifier-list">
              <template v-if="dish?.modifiers?.default?.ingredients">
                <button v-for="item in dish?.modifiers?.default?.ingredients" :key="item?.id" @click="modifierClick(item)" class="modifier active">{{ item?.name }}</button>
              </template>
              <template v-if="dish?.modifiers?.default?.modifiers">
                <button v-for="item in dish?.modifiers?.default?.modifiers" :key="item?.id" @click="modifierClick(item, true)" class="modifier">{{ item?.name }} {{ item?.price ? `+${item?.price}₴` : '' }}</button>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!hideBtns" class="dish-btns">
        <div v-if="!haveDaish" class="dish-btn btn-first s-text-caption">
          {{ DISHES_TRANSLATE?.dishes?.btn?.days?.select }}
        </div>
        <div class="dish-check-container">
          <label @click="checkDay(item, dish, index, dataModifiers)" class="s-text-caption" v-for="(item, index) in orders" :key="item.date">
            <div class="dish-btn" :class="{ active: checkDish(item, dish) }">
              <svg class="i-plus" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5V19M5 12H19" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
              </svg>
              <svg class="i-check" width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.835938 4.33333L4.16927 7.66667L10.8359 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              {{ item.short_name }}
            </div>
          </label>
        </div>
        <slot name="introduction"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import InfoMessage from "@/components/parts/info-message";
import { mapGetters } from "vuex";
export default {
  components: {
    InfoMessage
  },
  props: {
    dish: {
      type: Object
    },
    customClass: {
      type: String,
      default: ""
    },
    hideBtns: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modifPriceTotal: 0,
      modifWeight: 0,
      modFlag: false,
      dayComplete: false,
      haveDaish: false,
      activeIndexKKal: 0,
      dataModifiers: {
        add: [],
        remove: []
      }
    };
  },
  methods: {
    finalWeight(weight) {
      const newWeight = parseFloat(weight) + this.modifWeight;
      return newWeight < 0 ? 0 : newWeight;
    },
    modifierHeight() {
      const target = this.$el.querySelector(".modifier-list");
      if (target) {
        const targetHeight = target.clientHeight;
        target.style.height = `${targetHeight}px`;
        target.classList.add("hidden");
        target.classList.add("mounted");
      }
    },
    modifierToggle() {
      const target = this.$el.querySelector(".modifier-list");
      target.classList.toggle("hidden");
      this.modFlag = !this.modFlag;
    },
    modifierClick(item, modifier) {
      const target = event.target;
      const price = parseFloat(item?.price);
      const weight = parseFloat(item?.gram);
      if (target.classList.contains("active")) {
        this.modifWeight -= weight;
        if (modifier) {
          this.modifPriceTotal -= price;
          this.dataModifiers.add = this.dataModifiers.add.filter(elem => elem != item.id);
        } else {
          this.dataModifiers.remove.push(item.id);
        }
      } else {
        this.modifWeight += weight;
        this.dataModifiers.remove = this.dataModifiers.remove.filter(elem => elem != item.id);
        if (modifier) {
          this.modifPriceTotal += price;
          this.dataModifiers.add.push(item.id);
        }
      }
      this.modifPriceTotal = this.modifPriceTotal < 0 ? 0 : this.modifPriceTotal;
      target.classList.toggle("active");
    },
    switchVariant(index) {
      this.activeIndexKKal = index;
    },
    checkDish(day, dish) {
      if (!Object.keys(day?.items).length > 0) return false;
      let x;
      Object.values(day?.items).forEach(e => {
        if (e.product.id == dish.id) {
          x = true;
          this.haveDaish = true;
        }
      });
      if (x) return true;

      return false;
    },
    submitSlider() {
      this.$emit("submitSlider");
    },
    checkDay(day, dish, index, dataModifiers) {
      let variant_id = dish?.info.length > 0 ? dish?.info[this.activeIndexKKal].id : "";
      let el = this.$el.querySelector(".dish-card .dish-img");
      el.animate(
        [
          {
            transform: "scale(1)"
          },
          {
            transform: "scale(0.95)"
          },
          {
            transform: "scale(1)"
          }
        ],
        {
          easing: "linear",
          duration: 650,
          iterations: 1
        }
      );
      this.$emit("clickDay", { day, dish, index, variant_id, dataModifiers });
      const target = event.target.closest(".s-text-caption");
      if (document.querySelector(".s-text-caption.hvr-buzz-out")) {
        document.querySelector(".s-text-caption.hvr-buzz-out").classList.remove("hvr-buzz-out");
      }
      target.classList.add("hvr-buzz-out");
    }
  },
  computed: {
    ...mapGetters(["ORDERS", "DISHES_TRANSLATE", "DAY_COMPLETE"]),
    modifiersFlag() {
      return this.dataModifiers?.add.length || this.dataModifiers?.remove.length ? true : false;
    },
    finalPrice() {
      const newPrice = this.activePrice + this.modifPriceTotal;
      return newPrice < 0 ? 0 : newPrice;
    },
    modifiersLength() {
      const length = Object.keys(this.dish?.modifiers).length;
      return length;
    },
    activePrice() {
      return this.dish?.info?.[this.activeIndexKKal]?.price || 0;
    },
    orders() {
      let clon = JSON.parse(JSON.stringify(this.ORDERS));

      if (null === clon) {
        return clon;
      }

      const orders = Object.values(clon)?.filter((order) => {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1 < 10 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
        const date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
        const currentDate = `${year}-${month}-${date}`;

        return order?.date !== currentDate;
      });

      return orders;
    }
  },
  mounted() {
    this.modifierHeight();
  },
  watch: {
    ORDERS: {
      deep: true,
      handler() {
        this.ORDERS?.forEach(e => {
          if (!e && !e?.items?.length > 0) {
            this.haveDaish = true;
          } else {
            this.haveDaish = false;
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dish-container {
  padding-top: 67px;
  &.slide-card {
    width: 100%;
  }
}
.title-top {
  display: flex;
}
.title {
  padding-right: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ingredients-toggler {
  padding: 0 8px;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--dark-100);
  border: none;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.4px;
  background: var(--bg-main);
  border-radius: 8px;
  cursor: pointer;
  .angle {
    margin-left: 7px;
    transition: transform 0.5s ease;
    path {
      transition: stroke 0.5s ease;
    }
  }
  &.active {
    .angle {
      transform: rotate(180deg);
    }
  }
  &.colored {
    .angle path {
      stroke: var(--blue-main);
    }
  }
}
.modifier-list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  &:not(.mounted) {
    visibility: hidden;
    position: absolute;
    left: 0;
    width: calc(100% - 40px);
  }
  &.mounted {
    transition: margin-top 0.75s ease, height 0.5s ease;
  }
  &.hidden {
    margin-top: 0;
    height: 0 !important;
  }
}
.modifier {
  margin-bottom: 4px;
  padding: 0 8px;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: var(--dark-100);
  background: var(--bg-main);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  &.active {
    color: var(--bg-white);
    background: var(--blue-main);
  }
  &:not(:last-child) {
    margin-right: 4px;
  }
}
.dish-card {
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  width: 335px;
  min-height: 437px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: var(--white-100);
  border-radius: 10px;
  @media (max-width: 1199px) {
    min-height: 416px;
  }
}
.dish-img {
  filter: blur(0);
  max-width: 334px;
  width: 100%;
  max-height: 330px;
  position: absolute;
  top: -70px;
  left: -3px;
  cursor: pointer;
  transition: 0.5s ease;
  &:not([src]) {
    filter: blur(10px);
  }
  &:hover {
    margin-top: -3px;
  }
}
.dish-description {
  display: flex;
  align-items: flex-end;
  margin: 232px 0 20px;
}
.kkal-box {
  margin-top: 8px;
  display: flex;
  align-items: center;
  min-height: 24px;
}
.kkal-inner {
  margin-right: auto;
  display: flex;
  align-items: center;
}
.kkal-cell {
  margin-right: 4px;
  padding: 0 3px;
  cursor: pointer;
  min-width: 48px;
  font-size: 12px;
  height: 24px;
  background: var(--bg-main);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark-100);
  &.active {
    color: var(--white-100);
    background: var(--blue-main);
  }
}
.main-description {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.icons-box {
  display: flex;
}
.is-allergie {
  margin-right: 5px;
  cursor: default;
  position: relative;
}

.is-allergie:hover .info-meassage {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}

.price {
  margin-left: auto;
  white-space: nowrap;
}

.dish-btns {
  position: relative;
  .btn-first {
    transition: 0.2s;
    position: absolute;
    visibility: visible;
    text-transform: uppercase;
    color: var(--dark-100);
    opacity: 1;
    z-index: 1;
  }
  &:hover {
    .btn-first {
      transition: 0.2s;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
    }
  }
}
.dish-btn {
  user-select: none;
  // transition: 0.2s;
  color: var(--dark-100);
  width: 100%;
  height: 48px;
  cursor: pointer;
  background: var(--bg-main);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.dish-check-container {
  display: flex;
  label {
    width: 100%;
    margin: 0 2px;
  }
  input[type="checkbox"] {
    display: none;
  }
  .dish-btn {
    svg {
      display: none;
      height: 16px;
      width: 16px;
      margin-top: -2px;
      margin-right: 2px;
    }
  }
  label .dish-btn {
    &:hover {
      color: var(--white-100);
      background: var(--blue-main);
      svg.i-plus {
        display: flex;
      }
      svg.i-check {
        display: none;
      }
    }
  }

  .dish-btn.active {
    background: var(--blue-main);
    color: var(--white-100);
    svg.i-check {
      display: flex;
      width: 10px;
    }
  }

  .dish-btns {
    position: relative;
  }
}
@media (max-width: 1199px) {
  .dish-btns .btn-first {
    display: none;
  }
}
/* Buzz Out */
@-webkit-keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
.hvr-buzz-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-buzz-out.animated {
  -webkit-animation-name: hvr-buzz-out;
  animation-name: hvr-buzz-out;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.slideToggle-enter-active,
.slideToggle-leave-active {
  transition: margin-top 0.5s ease, height 0.5s ease;
}

.slideToggle-enter-from,
.slideToggle-leave-to {
  margin-top: 0;
  height: 0;
}
</style>
